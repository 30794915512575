<script setup>
import { useRouter } from 'vue-router'
import { useConfigStore, useUserStore } from '~/store/index'
// const {route} = useRoute().params //  necessaire (damien)?
// const {locale} = useI18n() //  necessaire (damien)?

await useUserStore().fetchUser()
await useConfigStore().fetchConfig()

if (useConfigStore().maintenance) {
    const router = useRouter()
    router.replace('/')
}
</script>

<template>
    <div lang="html">
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
    </div>
</template>
